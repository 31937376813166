import Image from "components/Image/Image";
import Video from "components/Video/Video";
import { Row } from "components/Grid/Grid";
import { OverviewRow, OverviewInfoCol, OverviewInfoItem, AltRowBackground } from "styles/caseStudy.styles";
import { ProcessRow, ProcessTitle, ProcessCol, ScreenshotRow, MobileScreenshot, InfoCol, ScreenshotCol, StyledSubhead, SeparatedSubhead, StyledCol } from "../../../../work/movie-app/movieApp.styles";
import * as React from 'react';
export default {
  Image,
  Video,
  Row,
  OverviewRow,
  OverviewInfoCol,
  OverviewInfoItem,
  AltRowBackground,
  ProcessRow,
  ProcessTitle,
  ProcessCol,
  ScreenshotRow,
  MobileScreenshot,
  InfoCol,
  ScreenshotCol,
  StyledSubhead,
  SeparatedSubhead,
  StyledCol,
  React
};